// To parse this data:
//
//   import { Convert, Info } from "./file";
//
//   const info = Convert.toInfo(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface Info {
  card: Card;
  profile: Profile;
}

export interface Card {
  address: string;
  location: Location;
  title: string;
  description: string;
  date: Date;
  slots: number;
  attendees: number;
  startTime: Date;
  endTime: Date;
  media: any[];
  feed: Feed[];
  _id: string;
}

export interface Feed {
  timestamp: number;
  name: string;
  message: string;
  _id: string;
}

export interface Location {
  address: string;
  coordinates: number[];
}

export interface Profile {
  name: string;
  image: string;
}

export interface MessageInput {
  name: string;
  message: string;
  btoken: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toInfo(json: string): Info {
    return cast(JSON.parse(json), r("Info"));
  }

  public static infoToJson(value: Info): string {
    return JSON.stringify(uncast(value, r("Info")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ""): never {
  if (key) {
    throw Error(
      `Invalid value for key "${key}". Expected type ${JSON.stringify(
        typ
      )} but got ${JSON.stringify(val)}`
    );
  }
  throw Error(
    `Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`
  );
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue("array", val);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue("Date", val);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue("object", val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return val; //invalidValue(typ, val);
  while (typeof typ === "object" && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

// function u(...typs: any[]) {
//     return { unionMembers: typs };
// }

function o(props: any[], additional: any) {
  return { props, additional };
}

// function m(additional: any) {
//     return { props: [], additional };
// }

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  Info: o(
    [
      { json: "card", js: "card", typ: r("Card") },
      { json: "profile", js: "profile", typ: r("Profile") },
    ],
    false
  ),
  Card: o(
    [
      { json: "address", js: "address", typ: "" },
      { json: "location", js: "location", typ: r("Location") },
      { json: "title", js: "title", typ: "" },
      { json: "description", js: "description", typ: "" },
      { json: "date", js: "date", typ: Date },
      { json: "slots", js: "slots", typ: 0 },
      { json: "attendees", js: "attendees", typ: 0 },
      { json: "startTime", js: "startTime", typ: Date },
      { json: "endTime", js: "endTime", typ: Date },
      { json: "media", js: "media", typ: a("any") },
      { json: "feed", js: "feed", typ: a(r("Feed")) },
      { json: "_id", js: "_id", typ: "" },
    ],
    false
  ),
  Feed: o(
    [
      { json: "timestamp", js: "timestamp", typ: 0 },
      { json: "name", js: "name", typ: "" },
      { json: "message", js: "message", typ: "" },
      { json: "_id", js: "_id", typ: "" },
    ],
    false
  ),
  Location: o(
    [{ json: "coordinates", js: "coordinates", typ: a("any") }],
    false
  ),
  Profile: o(
    [
      { json: "name", js: "name", typ: "" },
      { json: "image", js: "image", typ: "" },
    ],
    false
  ),
};
