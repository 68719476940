import { useState, useEffect, Fragment } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupsIcon from "@mui/icons-material/Groups";
import ShareIcon from "@mui/icons-material/Share";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import "./App.css";
import { Messenger } from "./Messenger";
import { getInfo } from "./api/getInfo";
import { Convert, Info } from "./types/GetInfoResponse";
import { API_URL } from "./constants";

const DEFAULT_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzEyYTkzOGViNzQ3YzlkYzY3MmYzYWYiLCJjYXJkSWQiOiI2MzEyYTk0N2ViNzQ3YzlkYzY3MmYzYjciLCJpYXQiOjE2NjI1OTkzNTZ9.ib5fP863wm5W7K0aLm911Ru4boz_LIWdY7cj2JYs4z0";

declare global {
  interface Window {
    opera: any;
    MSStream: any;
  }
}

function App() {
  const [info, setInfo] = useState({} as Info);
  const [showPopup, setShowPopup] = useState(false);
  const [btoken, setBtoken] = useState<string>("");

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const token = params.get("token");
    setBtoken(token || DEFAULT_TOKEN);
  }, []);

  useEffect(() => {
    if (btoken) {
      getInfo(btoken)
        .then((info: string) => {
          const cardInfo = Convert.toInfo(info);
          setInfo(cardInfo);
        })
        .catch(function (error: any) {
          console.error(error);
        });
    }
  }, [btoken]);

  const handleShare = () => {
    if (!showPopup) {
      navigator.clipboard.writeText(window.location.href);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    }
  };

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return "unknown";
  };

  const handleDownload = () => {
    const appStoreLink =
      getMobileOperatingSystem() === "ios"
        ? "https://apps.apple.com/app/friend-card-join-local-friends/id1642461717"
        : ""; // TODO: Add google play store link when available

    if (appStoreLink) {
      window.open(appStoreLink);
    }
  };

  if (!info.card || !btoken)
    return (
      <img
        className="loading"
        src={window.location.origin + "/splash.png"}
        alt="splash screen"
      />
    );
  else {
    return (
      <div className="App">
        <div className="main">
          <h4 className="header-text">Friend Card</h4>
          <div className="divider" />
          <div style={{ height: 36 }} />
          <h1 className="card-title">{info.card.title}</h1>
          <h3 className="card-description">
            {info.card.description.split("\n").map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
          </h3>
          <div style={{ height: 48 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <CalendarTodayIcon fontSize="large" />
            <div style={{ width: 14 }} />
            <span>{info.card.date.toDateString()}</span>
          </div>
          <div style={{ height: 16 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <AccessTimeIcon fontSize="large" />
            <div style={{ width: 14 }} />
            <span>
              {" "}
              {`${info.card.startTime.toLocaleString("en-US", {
                timeStyle: "short",
              })} -
                ${info.card.endTime.toLocaleString("en-US", {
                  timeStyle: "short",
                })}`}
            </span>
          </div>
          <div style={{ height: 16 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            {info.profile?.image ? (
              <img
                className="profile-sm"
                alt="profile"
                src={`${API_URL}/image?key=${info.profile.image}`}
              />
            ) : (
              <PersonIcon fontSize="large" />
            )}
            <div style={{ width: 14 }} />
            <span>
              Hosted by <strong>{info.profile.name}</strong>
            </span>
          </div>
          <div style={{ height: 16 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <GroupsIcon fontSize="large" />
            <div style={{ width: 14 }} />
            <span>
              <strong>{info.card.slots}</strong> spots left
            </span>
          </div>
          <div style={{ height: 16 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon fontSize="large" />
            <div style={{ width: 14 }} />
            <span>{info.card.address}</span>
          </div>
          <div style={{ height: 16 }} />
          <iframe
            title="map"
            height="175"
            loading="lazy"
            className="map"
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_API_KEY}&q=${info.card.location.coordinates[1]},${info.card.location.coordinates[0]}`}
            allowFullScreen={true}
          />
          <div style={{ height: 32 }} />
          <Messenger messages={info.card.feed} btoken={btoken} />
          <div style={{ height: 32 }} />
          <div className="divider" />
          <div style={{ height: 32 }} />
          <div>
            {/* <h3 className="blurb-title">
              Expand your friend group with
              <br />
              <strong>Friend Card</strong>
            </h3> */}
            <div className="blurb-description">
              <strong>How Friend Card works:</strong>
              <ol>
                <li>Create Friend Cards and invite your friends.</li>
                <li>Other users can request to join.</li>
                <li>
                  Accept requests if you think they'll vibe with you and your
                  friends.
                </li>
              </ol>
            </div>
            <div className="blurb-description">
              <strong>People use Friend Card to...</strong>
              <ul>
                <li>Find pickup basketball games 🏀</li>
                <li>Get more players for game night 🃏</li>
                <li>Fill that extra concert ticket 🎤</li>
              </ul>
            </div>
            <div>
              <a href="https://www.privacypolicygenerator.info/live.php?token=oW9qDyjYnKrNz1ub4uV4rjr1QUdIDKcr">
                Privacy Statement
              </a>
            </div>
          </div>
          <div className="share-button">
            <ShareIcon fontSize="large" onClick={handleShare} />
          </div>
          <div className={showPopup ? "share-popup-show" : "share-popup-hide"}>
            Link copied to clipboard
          </div>
        </div>
        <div className="download-button" onClick={handleDownload}>
          Download Friend Card
        </div>
      </div>
    );
  }
}

export default App;
