import { useState, useRef, useEffect } from "react";
import "./Messenger.css";
import SendIcon from "@mui/icons-material/Send";
import { Feed, MessageInput } from "./types/GetInfoResponse";
import { patchInfo } from "./api/getInfo";
import Textarea from "react-expanding-textarea";

export const Messenger = (props: { messages: Feed[], btoken: string }) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(props.messages);
  const messageBox = useRef<HTMLDivElement>(null);

  const resetInputFields = () => {
    setName("");
    setMessage("");
  };

  const scrollToBottom = () => {
    if (messageBox.current) {
      messageBox.current.scrollTop = messageBox.current.scrollHeight;
    }
  };

  let handleSubmit = (e: any) => {
    e.preventDefault();
    let messageObj: MessageInput = {
      name: name,
      message: message,
      btoken: props.btoken
    };
    patchInfo(messageObj)
      .then((response: any) => {
        if (response.feed) {
          setMessages(response.feed);
        }
      })
      .catch((error: any) => console.log(error));
    resetInputFields();
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <>
      <div className="messenger-main" ref={messageBox}>
        <div style={{ flex: 1 }} />
        {messages.map((message: Feed, index: number) => (
          <div
            style={{ display: "flex", flexDirection: "column" }}
            key={message._id}
          >
            {index !== 0 && <div style={{ height: 4 }} />}
            <Message text={message.message} author={message.name} />
          </div>
        ))}
      </div>
      <div>
        <div className="textfield-container">
          <input
            type="text"
            name="name"
            value={name}
            placeholder="Enter your name"
            className="textfield"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="textfield-container">
          <Textarea
            name="message"
            value={message}
            placeholder="Post a message to everyone"
            className="textfield"
            rows={1}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <div style={{ width: 8 }} />
          <SendIcon
            fontSize="medium"
            className="textfield-icon"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export const Message = (props: { text: string; author: string }) => {
  const { text, author } = props;

  return (
    <div className="message">
      <div>
        <strong>{author}</strong>: {text}
      </div>
    </div>
  );
};
