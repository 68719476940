var axios = require("axios");

const getInfo = (btoken) => {
  var config = {
    method: "get",
    url: `https://friendcard.herokuapp.com/api/web/card`,
    headers: {
      Authorization: `Bearer ${btoken}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return JSON.stringify(response.data);
    })
    .catch(function (error) {
      return JSON.stringify(error);
    });
};

const isValid = (name) => {
  return name !== "";
};

const patchInfo = (messageObj) => {
  if (!isValid(messageObj.message)) {
    console.log("Error: Name or message is invalid");
    return;
  }

  var data = JSON.stringify({
    ...(messageObj.name.length > 0 && { name: messageObj.name }),
    message: messageObj.message,
  });

  var config = {
    method: "patch",
    url: "https://friendcard.herokuapp.com/api/web/card",
    headers: {
      Authorization: `Bearer ${messageObj.btoken}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};

export { getInfo, patchInfo };
